import request from '@/utils/request'

// 根据Id获取会员活动详情
export function getMemberActivetyDetail(id) {
    return request({
        url: "/customer-service/lrActivity/queryActivity/" + id,
        method: 'post'
    })
}


// 会员活动新增、编辑
export function subMemberActivetyForm(data, type) { //type 1 编辑 0 新增
    return request({
        url: type ? "/customer-service/lrActivity/updaeActivity" : "/customer-service/lrActivity/addActivity" ,
        method: 'post',
        data
    })
}

//查看会员活动报名用户信息列表
export function getActivityUserInfoList (data) {
    return request({
        url: "/customer-service/lrActivity/activityUserInfo",
        method: 'post',
        data
    })
}


//删除会员活动
export function deleteMemberActivety (id) {
    return request({
        url: "/customer-service/lrActivity/delActivity?activityId=" + id,
        method: 'delete'
    })
}


//获取会员活动列表
export function getMemberActivetyList (data) {
    return request({
        url: "/customer-service/lrActivity/queryAllActivity",
        method: 'post',
        data
    })
}