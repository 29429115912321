<template>
  <div>
    <!-- 顶部搜索栏 -->
    <el-row>
      <el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container flx-row ali-c">
          <el-input
            placeholder="请输入活动标题"
            class="search-input"
            v-model="searchObj.activityTitle"
            clearable
          ></el-input>
          <el-select
            placeholder="请选择活动状态"
            class="search-input ml-15"
            v-model="searchObj.status"
            clearable
          >
            <el-option
              v-for="(item,index) in statusList"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <!-- 一期不做 -->
          <!-- <el-select placeholder="会员等级" class="search-input ml-15" v-model="searchObj.member_grade">
            <el-option
              v-for="(item,index) in member_grade"
              :key="index"
              :label="item.name"
              :value="index"
            ></el-option>
          </el-select>-->
          <el-date-picker
            class="ml-10"
            :clearable="false"
            v-model="date"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
          <el-button type="primary" icon="el-icon-search" class="ml-15" @click="search">查询</el-button>
          <el-button icon="el-icon-refresh" class="ml-15" @click="reset">重置</el-button>
        </div>
      </el-col>
    </el-row>
    <!-- 表格内容 -->
    <el-row class="mt-24">
      <el-col el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container">
          <!-- 操作栏 -->
          <div class="flx-row ali-c js-btw">
            <div class="flx-row ali-c">
              <router-link :to="{name:'Members_activity_new', params:{addFlag: true}}">
                <el-button type="primary">发布活动</el-button>
              </router-link>
            </div>
            <!-- <div class="flx-row ali-c">
              <el-button icon="el-icon-download" type="primary" plain>导入</el-button>
              <el-button icon="el-icon-upload2" class="ml-15" type="primary" plain>导出</el-button>
            </div>-->
          </div>

          <!-- 门店列表 -->
          <el-tabs v-model="searchObj.storeId" @tab-click="changeTab" class='mt-20'>
            <el-tab-pane
              :label="item.storeName"
              :name="item.id+''"
              v-for="(item,index) in store_list"
              :key="index"
            >
            </el-tab-pane>
          </el-tabs>

          <!-- 表格 -->
          <el-table class="mt-24 member-table" :data="tableData">
            <el-table-column label="活动标题" prop="activityTitle"></el-table-column>
            <el-table-column label="活动类型">
              <template slot-scope="scope">
                <span>{{scope.row.activityType==1?'普通活动':'会员活动'}}</span>
              </template>
            </el-table-column>
            <el-table-column label="报名条件">
              <template slot-scope="scope">
                <span>{{scope.row.enrollCondition==1? "免费报名":"积分报名"}}</span>
              </template>
            </el-table-column>
            <el-table-column label="报名时间" prop="registrationTime" width="350">
              <template slot-scope="scope">
                <span>{{scope.row.registrationStartTime}} 至 {{scope.row.registrationEndTime}}</span>
              </template>
            </el-table-column>
            <el-table-column label="活动状态" prop="status">
              <template slot-scope="scope">
                <span>
                  {{scope.row.status==1?'未开始':
                  scope.row.status==2?'报名中':
                  scope.row.status==3?'报名结束':
                  "活动结束"}}
                </span>
              </template>
            </el-table-column>
            <el-table-column label="已报名人数" prop="checkInTotal"></el-table-column>
            <el-table-column label="虚拟点赞人数" prop="virtualLike"></el-table-column>
            <el-table-column label="实际点赞人数" prop="likeTotal"></el-table-column>
            <el-table-column label="操作" width="250">
              <template slot-scope="scope">
                <div class="flx-row ali-c">
                  <el-link
                    type="primary"
                    :underline="false"
                    @click="searchActivity(scope.row.activityId)"
                  >
                    <i class="el-icon-info"></i>
                    查看报名用户
                  </el-link>
                  <router-link
                    :to="{name: 'Members_activity_new', params: {addFlag: false, activityId: scope.row.activityId}}"
                  >
                    <el-link type="primary" :underline="false" class="ml-10">
                      <i class="el-icon-edit-outline"></i>
                      编辑
                    </el-link>
                  </router-link>
                  <el-link
                    type="danger"
                    :underline="false"
                    class="ml-10"
                    @click="deleteAct(scope.row.activityId)"
                  >
                    <i class="el-icon-delete-solid"></i>
                    删除
                  </el-link>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <Pagination
            :total="total"
            :pageNum="pageNum"
            :pageSize="pageSize"
            :pageSizes="pageSizes"
            :type="'list'"
            @changePage="search"
            @syncPageData="syncPageData"
          ></Pagination>
        </div>
      </el-col>
    </el-row>
    <el-dialog :visible.sync="showRegisters">
      <div class="pad-container">
        <el-table class="mt-24 member-table" :data="registersData">
          <el-table-column label="用户昵称" prop="nickname"></el-table-column>
          <el-table-column label="会员姓名" prop="name"></el-table-column>
          <el-table-column label="手机号码" prop="phone"></el-table-column>
          <el-table-column label="报名人数" prop="enrollPerson"></el-table-column>
          <el-table-column label="报名时间" prop="createTime"></el-table-column>
        </el-table>
        <Pagination
          :total="regsTotal"
          :pageNum="regsPageNum"
          :pageSize="regsPageSize"
          :pageSizes="regsPageSizes"
          :hideSizes="true"
          :type="'dialog'"
          @changePage="changePage"
          @syncPageData="syncPageData"
        />
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import { getStoreList } from "@/api/online_department_store/commodity_manage"
import {getMemberActivetyList, getActivityUserInfoList, deleteMemberActivety } from "@/api/members/members_activity";
export default {
  name: "activity_list",
  components: {
    Pagination
  },
  data() {
    return {
      // 顶部搜索栏数据
      searchObj: {
        activityTitle: "",
        status: null,
        storeId: ""
      },
      date: [],
      // 活动状态列表,用于筛选
      statusList: [
        {
          id: 1,
          name: "未开始"
        },
        {
          id: 2,
          name: "报名中"
        },
        {
          id: 3,
          name: "报名结束"
        },
        {
          id: 4,
          name: "活动结束"
        }
      ],
      // 会员等级列表,用于筛选
      member_grade: [
        {
          name: "等级1"
        },
        {
          name: "等级2"
        },
        {
          name: "等级3"
        }
      ],
      store_list: [],
      // 会员表格数据内容
      tableData: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,
      pageSizes: [10, 20, 30, 50, 100],
      regsTotal: 0,
      regsPageNum: 1,
      regsPageSize: 5,
      regsPageSizes: [5, 8, 10, 12, 15],
      addFlag: true,
      showRegisters: false,
      activityId: null,
      registersData: []
    };
  },
  created() {
    this.getStores();
  },
  methods: {
    // 搜索事件
    search(ret) {
      if( ret == 1) {
        this.pageNum = 1
        this.pageSize = 10
      }
      let data = {
        activityTitle: this.searchObj.activityTitle,
        status: this.searchObj.status,
        registrationStartTime: this.date[0],
        registrationEndTime: this.date[1],
        storeId: this.searchObj.storeId,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      };
      getMemberActivetyList(data).then(res => {
        if (res.data.code == 200) {
          this.total = res.data.body.total;
          this.tableData = res.data.body.list;
        }
      })
    },
    // 重置搜索关键词
    reset() {
      this.searchObj = {
        activityTitle: "",
        status: null,
        storeId: String(this.store_list[0].id) 
      };
      this.date = [];
      this.search(1);
    },
     // 获取门店列表
    getStores() {
      getStoreList().then(res => {
        this.store_list = res.data.body.list;
         this.searchObj. storeId = String(this.store_list[0].id) 
        this.search(1);
      })
    },
    syncPageData(data, type) {
      if (type == "list") {
        this.pageNum = data.num;
        this.pageSize = data.size;
      }
      if (type == "dialog") {
        this.regsPageNum = data.num;
        this.regsPageSize = data.size;
      }
    },
    changePage(type) {
      if (type == "list") this.search();
      if (type == "dialog") this.checkTheSigned();
    },
    // 门店改变
    changeTab(tab, event) {
      this.searchObj.storeId = tab.name;
      this.search(1);
    },
    //删除活动
    deleteAct(id) {
      this.$confirm("此操作将永久删除该活动, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      })
        .then(() => {
          deleteMemberActivety(id).then(res => {
            this.getData();
            this.$message({
              message: "删除成功",
              type: "success"
            });
            this.search();
          })
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    searchActivity(id){
      this.showRegisters = true;
      this.activityId = id
      this.checkTheSigned()
    },
    checkTheSigned() {
      // this.merchantId = id ? id : this.merchantId
      let data = {
        activityId: this.activityId,
        pageSize: this.regsPageSize,
        pageNum: this.regsPageNum
      };
      getActivityUserInfoList(data).then(res => {
        this.regsTotal = res.data.body.total;
        this.registersData = res.data.body.list;
      })
    }
  }
};
</script>
<style lang="scss" scoped>
.member-table {
  .circle {
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }
}
.click-tip {
  color: #409eff;
  cursor: pointer;
}
</style>
